// 开标
<template>
    <div class="salary">
        <img id="u28_img" class="img "
            src="https://d1icd6shlvmxi6.cloudfront.net/gsc/SPMO4D/35/fe/1c/35fe1c747f0e476bad95385386cd538a/images/开票信息管理/u28.png?pageId=20a05258-19d6-4fba-8d3c-3385efd79805">
        <div style="padding: 20px;background-color: #fff;">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px"
                style="width: 30%;margin: 0 auto;">
                <el-form-item label="企业名称：">
                    <span>{{this.ruleForm.companyName}}</span>
                </el-form-item>
                <el-form-item label="税号：">
                    <span>{{this.code}}</span>
                </el-form-item>
                <el-form-item label="企业地址" prop="companyAddress">
                    <el-input v-model="ruleForm.companyAddress" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="companyPhone">
                    <el-input v-model="ruleForm.companyPhone"></el-input>
                </el-form-item>
                <el-form-item label="开户行名称" prop="companyBankName">
                    <el-input v-model="ruleForm.companyBankName"></el-input>
                </el-form-item>
                <el-form-item label="账号" prop="companyBankAccount">
                    <el-input v-model="ruleForm.companyBankAccount"></el-input>
                </el-form-item>
                <el-form-item label="邮寄地址" prop="receiveAddress">
                    <el-input v-model="ruleForm.receiveAddress"></el-input>
                </el-form-item>
                <el-form-item label="接收人" prop="receiveName">
                    <el-input v-model="ruleForm.receiveName"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="receivePhone">
                    <el-input v-model="ruleForm.receivePhone"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="receiveEmail">
                    <el-input v-model="ruleForm.receiveEmail"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},

    // 组件名称
    name: "",

    // 这里存放数据
    data() {
        return {
            code:"",
            ruleForm: {
                companyName:"",
                CAKeyId:"",
                from:10,
                companyAddress: "",
                companyPhone: "",
                companyBankName: "",
                companyBankAccount: "",
                receiveAddress: "",
                receiveName: "",
                receivePhone: "",
                receiveEmail: "",
            },
            bidAdviceNoteFilePath: "",
            callBidFilePath: "",
            firmName: "",
            CAKeyEncStr: "",
        };
    },

    // 监听属性 类似于data概念
    computed: {},

    // 监控data中的数据变化
    watch: {},

    // 方法集合
    methods: {
        submitForm(formName) {
            // this.$refs[formName].validate((valid) => {
                // if (valid) {
                    // 成功
                    // console.log("this",this.ruleForm);
                    this.axios({
                        method: "POST",
                        url: "/newGuarantee.do?saveCustmomerTicketInfo",
                        data: this.ruleForm,
                    }).then((res) => {
                        if (res.data.status == 0) {
                            this.$message({
                                message: "保存成功",
                                type: "success",
                            });
                            // this.$router.push("/main");
                        } else {
                            this.$message({
                                message: res.data.statusInfo,
                                type: "error",
                            });
                        }
                    });
                // } else {
                    // 失败
                //     return false;
                // }
            // });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        getEnterpriseMessage() {
            var type = window.sessionStorage.getItem("type");
            if (type == "CA") {
                var params = {
                    CAKeyId: this.firmName,
                    from: 10,
                    CAKeyEncStr: this.CAKeyEncStr,
                };
            } else if (type == "CFCA") {
                var params = {
                    CAKeyId: this.firmName,
                    from: 10,
                    CAKeyEncStr: window.sessionStorage.getItem("content"),
                };
            } else {
                var params = {
                    handsetNum: window.sessionStorage.getItem("phone"),
                    from: 10,
                };
            }
        },

        hunxian(){
            this.axios({
                        method: "POST",
                        url: "/newGuarantee.do?getCustmomerTicketInfo",
                        data: {
                            companyName:this.ruleForm.companyName,
                            CAKeyId:this.ruleForm.CAKeyId,
                            from:10,
                        },
                    }).then((res) => {
                        if(res.data.data){
                        // console.log("11111111111",res);
                        this.ruleForm=res.data.data;
                        this.ruleForm.companyName = window.sessionStorage.getItem("com_name");
                        this.ruleForm.CAKeyId = window.sessionStorage.getItem("firmName");
                        this.ruleForm.from=10;
                        }
                    });
        }
    },

    // 生命周期 - 创建完成（可以访问当前this实例）
    created() { },

    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        // console.log(window.sessionStorage.getItem("firmName"));
        this.ruleForm.companyName = window.sessionStorage.getItem("com_name");
        this.code = window.sessionStorage.getItem("socialCode");
        this.ruleForm.CAKeyId = window.sessionStorage.getItem("firmName");
        this.CAKeyEncStr = window.sessionStorage.getItem("date");
        this.getEnterpriseMessage();
        this. hunxian();

    },

    // 生命周期 - 创建之前
    beforeCreate() { },

    // 生命周期 - 挂载之前
    beforeMount() { },

    // 生命周期 - 更新之前
    beforeUpdate() { },

    // 生命周期 - 更新之后
    updated() { },

    // 生命周期 - 销毁之前
    beforeDestroy() { },

    // 生命周期 - 销毁完成
    destroyed() { },

    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },

    // keep-alive缓存离开之后触发
    deactivated() { },
};
</script>

<style scoped>
.salary {
  height: 100%;
}
.cardTop-middle {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 153px;
  position: relative;
}
.content {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid rgb(51, 126, 190);
  text-align: center;
  color: rgb(51, 126, 190);
}
.explain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.part {
  text-align: center;
  font-size: 12px;
  color: rgb(51, 126, 190);
}
.befor_1 {
  position: absolute;
  left: -91px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -78px;
  color: #f56c6c;
}
</style>